<template>
  <div class="item-list relative" v-if="item">
    <div class="card-farms">
      <div class="absolute right-0 z-10">
        <!-- Favorito -->
        <button
          @click="addDeleteFarm(item.id)"
          class="flex items-center justify-center w-8 h-8 bg-agrogo-yellow-1 absolute rounded-bl-2xl rounded-tr-xl right-0"
        >
          <span
            :class="[
              item?.isFavorite
                ? 'icon-heart text-xs text-agrogo-green-1'
                : 'icon-heart text-xs text-white hover:text-agrogo-green-2',
            ]"
          ></span>
        </button>
      </div>
      <router-link :to="`/finca/${item.slug}`">
        <div class="grid grid-cols-1">
          <div class="bg-card-farm">
            <img
              class="max-w-none w-full"
              :src="getImage(item)"
              loading="lazy"
              alt=""
              data-src="@/assets/images/previewimg.png"
              data-srcset="@/assets/images/previewimg.png"
            />
            <div class="publicationcategory bg-agrogo-green-7">
              <p
                v-if="item.priority.length > 0"
                class="font-nexa font-normal text-xs text-white lowercase fist-letter"
              >
                {{ textFarm(item.priority[0]) }}
              </p>
              <p class="text-agrogo-green-6 size-star space-x-px">
                <span
                  class="icon-star2"
                  v-for="(start, i) in starts"
                  :key="i"
                  :class="{
                    'text-agrogo-green-6': start.isActive,
                    'text-agrogo-green-5': !start.isActive,
                  }"
                />
                <!-- <span class="icon-star2"></span><span class="icon-star2"></span
                ><span class="icon-star2"></span><span class="icon-star2"></span
                ><span class="icon-star2 text-agrogo-green-5"></span> -->
              </p>
            </div>
            <!-- <a
              class="icon-isologo-agrogo--1 text-3xl text-white absolute right-0 bottom-0 pr-1.5 pb-1 opacity-30 md:text-3xl md:pr-3 md:pb-3"
            ></a> -->
          </div>
          <div class="content-card-farm">
            <span
              class="font-dinpro font-normal text-xs text-agrogo-yellow-1 text-left md:text-sm opacity-80 pb-1.5 md:pb-2"
              >ID# <span class="text-agrogo-gray-1">{{ item.id }}</span></span
            >
            <div class="md:hidden overflow-hidden">
              <p
                class="font-nexa font-normal text-white text-xs text-left line-clamp"
              >
                {{ item.name }}
              </p>
            </div>
            <div class="hidden md:inline-block overflow-hidden md:h-14">
              <p
                class="h-full font-nexa font-normal text-white text-xs text-left md:text-base lg:text-xl line-clamp"
              >
                {{ item.name }}
              </p>
            </div>
            <div class="">
              <p
                class="font-dinpro font-medium text-xs text-agrogo-yellow-1 text-right leading-none md:text-sm"
              >
                Precio total
              </p>
              <p
                class="font-dinpro font-bold text-xl text-white text-right md:text-2xl"
              >
                <span
                  class="icon-signo-pesos text-sm md:text-xl text-agrogo-yellow-1 pr-1"
                ></span
                >{{ formatThousand(item.price.value) }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { API_ROUTES } from "@/config";
import { generateHash } from "@/utils";
import alertMessageMixin from "@/mixins/alertMessageMixin";
import { mapState } from "vuex";

export default {
  inject: ["provider"],
  mixins: [alertMessageMixin],
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      starts: [
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
        {
          isActive: false,
        },
      ],
      myFarmRepository: this.provider.myFarmRepository,
    };
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  watch: {
    item() {
      const me = this;
      me.starts.forEach((el) => (el.isActive = false));
      me.fillStart(me.item);
    },
  },
  methods: {
    // agrega los puntos de miles a un numero
    formatThousand(number) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        currency: "COP",
        minimumFractionDigits: 0,
      });
      number = formatterPeso.format(number);
      return number;
    },
    // carga la imagen desde algolia
    getImage(item) {
      let urlImg = null;
      if (item.multimedia_property.length > 0) {
        urlImg = `${API_ROUTES.image.get}${item.multimedia_property[0].media.medium_url}`;
      } else {
        urlImg = require("@/assets/images/previewimg.png");
      }
      return urlImg;
    },
    fillStart(item) {
      const me = this;
      if (item.priority.length > 0) {
        if (item.priority[0].id == 1 || item.priority[0].id == 2) {
          // Super
          me.starts.forEach((el) => {
            el.isActive = true;
          });
        } else if (item.priority[0].id == 3) {
          // Plus
          me.starts.forEach((el, index) => {
            if (index <= 3) {
              el.isActive = true;
            }
          });
        } else if (item.priority[0].id == 4) {
          // Alto
          me.starts.forEach((el, index) => {
            if (index <= 2) {
              el.isActive = true;
            }
          });
        } else if (item.priority[0].id == 5) {
          // Medio
          me.starts.forEach((el, index) => {
            if (index <= 1) {
              el.isActive = true;
            }
          });
        } else {
          // Básico
          me.starts.forEach((el, index) => {
            if (index <= 0) {
              el.isActive = true;
            }
          });
        }
      }
      // Super 5 estrellas1
      // Plus 4 estrellas2
      // Alto 3 3
      // Medio 2 4
      // Basico 1 5
    },
    textFarm(item) {
      let text = "";
      if (item.id == 1 || item.id == 2) {
        text = "Súper";
      } else {
        text = item.name;
      }
      return text;
    },
    // agrega o elimina el like
    async addDeleteFarm(id) {
      const me = this;
      if (me.isAuthenticated) {
        try {
          const dataAditional = await generateHash();
          let { data: data } = await me.myFarmRepository.delete(
            dataAditional,
            id
          );
          me.alertMenssage(data.response, 5000);
        } catch (error) {
          me.alertMenssage(error.response.data.response, 5000);
        }
      } else {
        me.alertMenssage(
          "Para poder agregar esta finca a tus favoritas debes iniciar sesión",
          5000
        );
      }
    },
  },
  mounted() {
    const me = this;
    me.fillStart(me.item);
  },
};
</script>
<style>
.fist-letter:first-letter {
  text-transform: uppercase;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<template>
  <PxHeader />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="bg-agrogo-green-2 sizeMinAllView md:pb-16">
    <publish-button />
    <!-- CARRUSEL Tipos de fincas -->
    <div class="hidden md:flex h-9"></div>
    <div class="">
      <div
        class="flex bg-white md:mx-8 lg:mx-16 md:rounded-t-50 md:space-x-4 lg:space-x-8 2xl:space-x-12 2xl:space-x-14"
      >
        <!-- IMAGEN Y DESCRIPCION -->
        <div class="main w-full md:w-1/2 md:pl-8 lg:pl-24">
          <div
            v-if="isBusy"
            class="px-3 py-6 h-auto flex justify-center md:py-12"
          >
            <div>
              <img
                src="@/assets/images/loaderagrogo.gif"
                class="sizeGifLoading"
                alt=""
              />
            </div>
          </div>
          <div
            v-else
            class="md:pt-10 pt-2 grid grid-cols-1 md:overflow-hidden md:gap-x-3 lg:gap-x-5 bg-agrogo-green-2 md:bg-white md:rounded-t-2xl"
          >
            <div class="">
              <div class="md:grid md:grid-cols-1 md:bg-white">
                <slider-img
                  v-if="records.length > 0"
                  :records="records"
                  :record="record"
                  :dataFarm="farm"
                  :isFavorite="isFavorite"
                  @addDeleteFarm="addDeleteFarm"
                />
                <!-- CONTENEDOR DESCRIPCION DESKTOP -->
                <div
                  class="hidden md:flex items-center justify-start ml-10 my-7"
                >
                  <a class="font-nexa lg:text-2xl text-agrogo-green-2"
                    ><span class="font-black">Descripción </span>de la Finca
                  </a>
                </div>
              </div>
              <!-- TEXTO DEBAJO DETALLES DE FICA MOBILE -->
              <div class="md:hidden bg-agrogo-green-2 pb-8 relative">
                <div class="flex flex-col justify-center pt-1 pb-4 px-4">
                  <div class="flex justify-between">
                    <a class="font-dinpro text-xs text-agrogo-yellow-1"
                      >ID#
                      <span v-if="farm" class="text-sm text-agrogo-gray-1">{{
                        farm.id
                      }}</span></a
                    >
                    <span
                      v-if="farm?.state?.id == 5"
                      class="text-sm font-dinpro text-agrogo-green-2"
                      >Vendida</span
                    >
                  </div>
                  <a
                    v-if="farm"
                    class="font-nexa font-normal text-white text-xl text-left"
                  >
                    {{ farm.area.value }}
                    {{ farm.area.unit ? farm.area.unit : "" }}
                    {{ farm.municipalities.name }}, {{ farm.departments.name }}
                  </a>
                </div>
                <div class="flex">
                  <div
                    v-if="farm"
                    class="flex flex-col w-1/2 items-start justify-center space-y-2 pl-5"
                  >
                    <div class="flex w-full">
                      <span
                        class="text-agrogo-yellow-1 icon-flower text-sm text-center w-1/6 pr-1"
                      ></span>
                      <div class="flex items-center justify-start">
                        <span
                          class="font-dinpro font-medium text-base text-agrogo-yellow-1 w-full"
                          >Tipo de Finca:</span
                        >
                      </div>
                    </div>

                    <div
                      v-for="(item, index) in farm.subcategory"
                      :key="index"
                      class="flex w-full"
                    >
                      <span
                        class="text-base text-agrogo-gray-1 text-center w-1/5 pr-1"
                        :class="item.icon"
                      ></span>
                      <div class="flex items-center justify-start">
                        <span
                          class="font-dinpro font-normal text-base text-agrogo-gray-1"
                          >{{ item.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-1/2 justify-start items-end pr-5">
                    <a
                      class="font-dinpro text-base font-medium text-agrogo-yellow-1"
                      >Precio Total</a
                    >
                    <a
                      v-if="farm"
                      class="font-dinpro font-bold text-lg text-agrogo-gray-1"
                      ><span
                        class="text-base icon-signo-pesos leading-none text-agrogo-yellow-1"
                      ></span>
                      {{ format(farm.price.value) }}</a
                    >
                    <a
                      v-if="farm"
                      class="font-dinpro text-2xs text-agrogo-gray-1 md:text-base"
                      >({{ farm.price.unit }})</a
                    >
                  </div>
                </div>
                <div class="md:hidden absolute inset-x-0 -bottom-5" v-if="farm">
                  <button
                    @click.prevent="toShare"
                    class="btn-primary-yellow w-36 h-10 text-agrogo-green-2 font-bold rounded-xl"
                  >
                    <div class="flex items-center justify-center">
                      <span class="icon-whatsapp text-xl mr-1"></span>
                      <span class="">Whatsapp</span>
                    </div>
                  </button>
                </div>
              </div>
              <div
                class="flex items-center flex-col justify-center h-28 bg-white md:hidden"
              >
                <div class="text-center mb-4 mt-8">
                  <button
                    @click.prevent="shareFarm"
                    class="flex items-center p-1 bg-agrogo-green-3 rounded-md"
                  >
                    <span class="mr-1 ml-1">
                      <img
                        class="img-icon-share"
                        src="@/assets/images/share.png"
                        alt=""
                      />
                    </span>
                    <span
                      class="font-dinpro font-normal text-base text-white mr-1"
                      >Compartir</span
                    >
                  </button>
                </div>
                <span class="text-agrogo-green-2 font-nexa font-normal text-lg">
                  <span class="font-black">Descripción</span> de la Finca</span
                >
              </div>
            </div>
          </div>

          <!-- CONTAINER CARDS  -->
          <farm-description :data="farm" />
        </div>
        <!-- TEXTO DETALLES DE FINCA DESKTOP-->
        <div class="hidden md:flex side w-1/2 md:rounded-tr-50 pt-10">
          <div class="hidden md:flex flex-col w-full">
            <div class="flex flex-col justify-center pb-8">
              <div class="flex justify-between">
                <a
                  class="font-dinpro font-normal text-base text-agrogo-green-4 pb-2"
                  >ID#
                  <span v-if="farm" class="text-agrogo-black-1">{{
                    farm.id
                  }}</span></a
                >
                <span
                  v-if="farm?.state?.id == 5"
                  class="text-sm font-dinpro text-agrogo-green-2"
                  >Vendida</span
                >
              </div>
              <a
                v-if="farm"
                class="font-nexa font-normal text-agrogo-black-1 text-2xl lg:text-3xl text-left"
              >
                {{ farm.area.value }}
                {{ farm.area.unit ? farm.area.unit : "" }}
                {{ farm.municipalities.name }}, {{ farm.departments.name }}
              </a>
            </div>
            <div class="grid grid-cols-2 pb-4">
              <div
                v-if="farm"
                class="grid grid-cols-1 place-items-start place-content-center gap-y-2"
              >
                <a
                  class="font-dinpro lg:text-base font-medium text-agrogo-green-4 pb-2"
                  ><span class="icon-flower text-base"></span> Tipo de Finca:
                </a>
                <div
                  v-for="(item, index) in farm.subcategory"
                  :key="index"
                  class="flex w-full"
                >
                  <span
                    class="text-2xl text-agrogo-black-1 text-center w-1/3 lg:w-1/4"
                    :class="item.icon"
                  ></span>
                  <div class="flex items-center justify-start">
                    <span
                      class="font-dinpro font-normal text-base text-agrogo-black-1"
                      >{{ item.name }}</span
                    >
                  </div>
                </div>
                <div class="md:pl-0 md:pt-5 flex" v-if="farm">
                  <button
                    @click.prevent="toShare"
                    class="btn-primary-yellow md:w-32 lg:w-40 h-11 lg:text-base flex items-center justify-center"
                  >
                    <span class="icon-whatsapp lg:text-2xl mr-1"></span>
                    Whatsapp
                  </button>
                </div>
                <div class="md:pl-0 md:pt-5" v-if="farm">
                  <button
                    @click.prevent="shareFarm"
                    class="flex items-center p-1 bg-agrogo-green-3 rounded-md"
                  >
                    <span class="mr-1 ml-1">
                      <img
                        class="img-icon-share"
                        src="@/assets/images/share.png"
                        alt=""
                      />
                    </span>
                    <span
                      class="font-dinpro font-normal text-base text-white mr-1"
                      >Compartir</span
                    >
                  </button>
                </div>
              </div>
              <div class="">
                <div class="flex flex-col justify-start w-auto">
                  <a
                    class="font-dinpro font-medium text-base text-agrogo-green-4 text-right pb-1.5"
                    >Precio Actual</a
                  >
                  <a
                    v-if="farm"
                    class="font-dinpro font-bold text-xl lg:text-2xl xl:text-3xl text-agrogo-black-1 text-right"
                    ><span
                      class="icon-signo-pesos text-xl xl:text-2xl leading-none text-agrogo-green-4"
                    ></span>
                    {{ format(farm.price.value) }}</a
                  >
                  <a
                    v-if="farm"
                    class="font-dinpro font-normal text-2xs text-agrogo-black-1 pl-8 xl:pl-0 text-right"
                    >({{ farm.price.unit }})</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CONTENEDOR COMPARTIR FINCA -->
    <!-- <share-farm v-if="farmStateId == 1" /> -->
    <!-- CONTENEDOR FINCAS SIMILARES -->
    <div v-if="similaryFarm.length > 0">
      <div
        class="bg-agrogo-yellow-1 h-12 flex items-center justify-between pl-8 md:h-16 md:pl-24 lg:pl-32"
      >
        <a class="font-nexa text-agrogo-green-2 text-base text-left md:text-2xl"
          ><span class="font-black">Fincas </span>Similares</a
        >
      </div>
      <!-- CARDSFARMS -->
      <div
        class="bg-white grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 place-content-center place-items-center gap-2 md:gap-3.5 px-2 pt-2 pb-2 md:px-4 lg:px-4 md:pt-2.5 md:pb-4"
      >
        <div v-for="(item, index) in similaryFarm" :key="index">
          <card-farms
            :item="item"
            @addRemovefavorite="AddDeleteFarmSimilary($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
// Mixins
import validationToken from "@/mixins/validationToken";
import validationMixin from "@/mixins/validationMixin";
import loginRegisterMixin from "@/mixins/loginRegisterMixin";
// components
import PxHeader from "@/components/header/PxHeader";
import CardFarms from "./components/CardFarms";
import SliderImg from "./components/SliderImg";
import FarmDescription from "./components/FarmDescription";
// import ShareFarm from "./components/ShareFarm";
import PublishButton from "@/components/publishButton/PublishButton";
import { mapState } from "vuex";

export default {
  inject: ["provider"],
  mixins: [validationMixin, loginRegisterMixin, validationToken],
  name: "Home",
  components: {
    CardFarms,
    PxHeader,
    SliderImg,
    FarmDescription,
    // ShareFarm,
    PublishButton,
  },

  data() {
    const me = this;
    return {
      slug: me.$route.params.slug,
      records: [],
      record: null,
      farm: null,
      farmData: null,
      farmStateId: null,
      isBusy: false,
      repository: this.provider.authRepository,
      repositoryFarmDetail: this.provider.farmDetailRepository,
      myFarmRepository: this.provider.myFarmRepository,
      favoriteRepository: me.provider.favoriteRepository,
      similaryFarm: [],
      favorites: [],
      parameterIdFarm: 5,
      isFavorite: false,
    };
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  methods: {
    async loadFarm() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.myFarmRepository.find(me.slug, dataAditional);
        me.farmStateId = data.state.id;
        me.farmData = data;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
        me.$router.push("/no-se-encontro-lo-que-buscabas");
      }
    },
    async loadFarmPreview() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.myFarmRepository.findFarmPreview(me.slug, dataAditional);
        me.farm = data;
        me.fillData();
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
        me.$router.push("/no-se-encontro-lo-que-buscabas");
      } finally {
        me.isBusy = false;
      }
    },
    fillData() {
      const me = this;
      me.isFavorite = me.farm.favorite;
      if (me.farm.multimedia_property.length > 0) {
        me.farm.multimedia_property.forEach((el) => {
          if (el.type == 2) {
            me.records.push({
              ...el,
              urlImg: require("@/assets/images/previewvideo.png"),
              urlVideo: `${API_ROUTES.image.get}${el.media.default_url}`,
            });
          } else {
            let width = screen.width;
            if (width >= "1024") {
              me.records.push({
                ...el,
                urlImg: `${API_ROUTES.image.get}${el.media.large_url}`,
                small_url: `${API_ROUTES.image.get}${el.media.small_url}`,
              });
            } else if (width <= "720") {
              me.records.push({
                ...el,
                urlImg: `${API_ROUTES.image.get}${el.media.medium_url}`,
                small_url: `${API_ROUTES.image.get}${el.media.small_url}`,
              });
            } else {
              me.records.push({
                ...el,
                urlImg: `${API_ROUTES.image.get}${el.media.large_url}`,
                small_url: `${API_ROUTES.image.get}${el.media.small_url}`,
              });
            }
          }
        });

        me.records.sort((a, b) => {
          if (a.type < b.type) {
            return 1;
          }
          if (a.type > b.type) {
            return -1;
          }
          return 0;
        });
      } else {
        me.records.push({
          urlImg: require("@/assets/images/previewimg.png"),
          type: 1,
          id: 10,
        });
      }

      me.record = me.records[0];

      me.farm.attribute_groups.forEach((el) => {
        if (el.id == 1) {
          el.attribute.forEach((x) => {
            x.order = 3;
            x.type = 1;
          });

          el.attribute.push({
            // ...me.farm.departments,
            id_name: "Departamento",
            order: 1,
            type: 2,
            value_attributes: [{ value: me.farm.departments.name }],
            type_attributes_input: {
              id: null,
              name: "ubication",
            },
          });

          el.attribute.push({
            // ...me.farm.municipalities,
            id_name: "Municipio",
            order: 2,
            type: 2,
            value_attributes: [{ value: me.farm.municipalities.name }],
            type_attributes_input: {
              id: null,
              name: "ubication",
            },
          });

          el.attribute.sort((a, b) => {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            return 0;
          });
        }
      });

      me.farm.attribute_groups.forEach((el) => {
        let counter = 0;
        el.attribute.forEach((x) => {
          counter++;
          let isActive = null;

          if (counter % 2 == 0) {
            isActive = "bg-agrogo-gray-2";
          } else {
            isActive = "";
          }

          x.isStyle = isActive;
        });
      });

      me.farm.attribute_groups.forEach((el) => {
        if (el.attribute.length > 0) {
          el.attribute.forEach((x, index) => {
            if (x.id == 8) {
              x.value_attributes.forEach((y) => {
                if (y.value == "0") el.attribute.splice(index, 1);
              });
            }
          });
        }
      });
    },
    format(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO");

      return formatterPeso.format(price);
    },
    //LISTA DE FINCAS SIMILARES
    async getSimilaryFarm() {
      const me = this;
      try {
        me.similaryFarm = [];
        const dataAditional = await generateHash();
        const { data: data } = await me.repositoryFarmDetail.getAll(
          dataAditional,
          me.farm.id
        );
        data.properties.forEach((el) => {
          me.similaryFarm.push({
            ...el,
            name: `${el.area.value} ${el.area.unit} ${el.municipalities.name}, ${el.departments.name}`,
            isFavorite: false,
          });
        });
        me.updateFarm();
      } catch (error) {
        // console.log(error);
      }
    },
    //AGREGA O ELIMINA EL FAVORITO SIMILARY
    async AddDeleteFarmSimilary(id) {
      const me = this;
      try {
        const dataAditional = await generateHash();
        let { data: data } = await me.myFarmRepository.delete(
          dataAditional,
          id
        );
        me.alertMenssage(data.response, 6000);
        me.updateFarm();
      } catch (error) {
        // console.log(error);
      }
    },
    async addDeleteFarm(favority) {
      const me = this;
      try {
        me.isFavorite = favority;
        const dataAditional = await generateHash();
        let { data: data } = await me.myFarmRepository.delete(
          dataAditional,
          me.farm.id
        );
        me.farm.favorite = favority;
        me.alertMenssage(data.response, 6000);
      } catch (error) {
        me.isFavorite = me.farm.favorite;
        me.alertMenssage(error.response.data.response, 6000);
      }
    },
    //REUTILIZA LA LIBRERIA DE ALERT PARA REDUCIR EL CODIGO
    alertMenssage(text, time) {
      const me = this;
      me.$swal({
        iconHtml:
          '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        customClass: {
          icon: "no-border-swal-alert",
        },
        text: text,
        showConfirmButton: true,
        timer: time,
      });
    },
    async loadData() {
      const me = this;
      await me.loadFarm();
      if (me.farmStateId == 1) {
        me.farm = me.farmData;
        me.fillData();
        me.isBusy = false;
      } else if (me.farmData) {
        me.farm = null;
        await me.loadFarmPreview();
      }
      await me.getSimilaryFarm();
    },
    toShare() {
      const me = this;
      let a = document.createElement("a");
      const link = `${me.farm?.share_wpp?.value}${window.location.href}`;

      a.href = link;
      a.target = "_blank";
      a.click();
    },
    shareFarm() {
      let a = document.createElement("a");
      const link = `https://api.whatsapp.com/send?phone=&text=Hola%20👍🏽%0A🚀%20Mira%20esta%20Finca%20que%20encontré%20en%20AGRO%20GO:%0A${window.location.href}`;
      a.href = link;
      a.target = "_blank";
      a.click();
    },
    async loadFavorites() {
      const me = this;
      me.favorites = [];
      try {
        const dataAditional = await generateHash();
        const {
          data: { response },
        } = await me.favoriteRepository.getAll({
          ...dataAditional,
        });
        me.favorites = response;
      } catch (error) {
        me.favorites = [];
        // console.log(error);
      }
    },
    async updateFarm() {
      const me = this;
      me.favorites = [];
      if (me.isAuthenticated) {
        await me.loadFavorites();
      }

      if (me.favorites.length > 0) {
        me.similaryFarm.forEach((el) => {
          const farm = me.favorites.find((x) => el.id == x.property_id);
          el.isFavorite = farm ? true : false;
        });
      } else {
        me.similaryFarm.forEach((el) => {
          el.isFavorite = false;
        });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    const me = this;
    me.slug = to.params.slug;
    me.loadData();
    next();
  },
  async mounted() {
    const me = this;
    await me.loadData();
    window.scroll(0, 0);
  },
};
</script>

<style lang="css" scoped>
.img-icon-share {
  width: 18px;
  height: 18px;
}
@media (min-width: 768px) {
  .md\:bg-white {
    background-color: #ffffff !important;
  }
}
</style>

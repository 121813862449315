<template>
  <div
    class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-10 lg:pr-7"
    :class="item.isStyle"
  >
    <a
      class="font-dinpro font-medium text-agrogo-black-1 text-sm text-right lg:text-lg"
      >{{ item.name }}</a
    >
    <div>
      <div
        v-for="(att, index) in item.subattributes"
        :key="index"
        class="font-dinpro text-agrogo-black-1 text-sm lg:text-lg flex justify-end"
      >
        {{ contentValue(att) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    contentValue(item) {
      let val = null;

      if (item.value_attributes.length > 0) {
        val = item.name;
      }
      return val;
    },
  },
};
</script>

<style></style>
